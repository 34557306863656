import http from '@/http'
import authHeader from './auth-header'

class ProvaService {
    async init(idCuco) {
        const response = await http.get(`prova/${idCuco}`, { headers: authHeader() })
        return response
    }
    async start(idCuco) {
        const response = await http.get(`prova/${idCuco}/iniciar`, { headers: authHeader() })
        return response
    }
    async status(idCuco) {
        const response = await http.get(`prova/${idCuco}/status`, { headers: authHeader() })
        return response
    }
    async save(idCuco, data) {
        const response = await http.post(`prova/${idCuco}/salvar`, data, { headers: authHeader() })
        return response
    }
    async saveEnd(idCuco, data) {
        const response = await http.post(`prova/${idCuco}/salvar/final`, data, { headers: authHeader() })
        return response
    }
    async end(idCuco) {
        const response = await http.get(`prova/${idCuco}/finalizar`, { headers: authHeader() })
        return response
    }
    // async getById(idCuco) {
    //     const response = await http.get(`aluno/edicoes/${idCuco}`, { headers: authHeader() })
    //     return response
    // }
    // async getInscricaoUserById(idCuco) {
    //     const response = await http.get(`aluno/edicoes/${idCuco}/inscricao`, { headers: authHeader() })
    //     return response
    // }

    // async finalizarInscricao(idCuco) {
    //     const response = await http.get(`aluno/edicoes/${idCuco}/inscricao/finalizar`, { headers: authHeader() })
    //     return response
    // }
    // async getStatusInscricao(idCuco) {
    //     const response = await http.get(`aluno/edicoes/${idCuco}/inscricao/status`, { headers: authHeader() })
    //     return response
    // }
}

export default new ProvaService()
