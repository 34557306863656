<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
.bg-menu-quiz {
  background-color: #e0e0e0 !important;
}
.frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.5);
  -moz-osx-font-smoothing: grayscale;
  z-index: 999999;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}

.modal-error {
  width: 300px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.4);
  text-align: center;
  overflow: hidden;
  -webkit-animation: show-modal 0.7s ease-in-out;
  animation: show-modal 0.7s ease-in-out;
}
.modal-error.hide {
  -webkit-animation: hide-modal 0.6s ease-in-out both;
  animation: hide-modal 0.6s ease-in-out both;
}
.modal-error img {
  margin-top: 24px;
}
.modal-error .title-erro {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin: 0px 0 15px 0;
  list-style: 2px;
}
.modal-error p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0 30px;
}
.modal-error a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: #fff;
}
.modal-error .button {
  background: #ec3434;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  margin-top: 20px;
  padding: 15px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-error .button:hover {
  background: #ec3434;
}
.icon-modal-error {
  color: #ec3434;
  font-size: 4rem !important;
  margin: 20px 0 6px;
}
@-webkit-keyframes show-modal {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes show-modal {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes hide-modal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes hide-modal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
