<template>
  <div class="frame" v-if="alertError.status">
    <div class="modal-error">
      <v-icon class="icon-modal-error" icon="mdi-alert"></v-icon>
      <span class="title-erro">ERRO</span>
      <p>{{ alertError.message }}</p>

      <a v-if="alertError.type === 1" :href="`/edicao/${idCuco}/instrucoes`">
        <div class="button">OK</div>
      </a>

      <div
        v-if="alertError.type === 2"
        @click="alertError.status = false"
        class="button"
      >
        OK
      </div>
    </div>
  </div>
  <div class="loading-full" v-if="loadingPage">
    <span class="loader"></span>
  </div>
  <div class="start-prova">
    <div class="header-instrucoes">
      <div class="logo-prova">
        <v-img
          :src="require('@/assets/img/logo-c.png')"
          max-height="50"
        ></v-img>
      </div>
      <div class="text-edicao">EDIÇÃO <b>CUCO 2024</b></div>
    </div>

    <div class="box-start-prova">
      <h3 class="title-instrucao">INSTRUÇÕES</h3>
      <p class="sub-title-instrucao">
        Olá <b>{{ user }}</b
        >. Leia as instruções com atenção antes de iniciar a prova!
      </p>
      <div class="item-instrucao-prova">
        <span>1.</span>
        O sistema de provas se encerra automaticamente às 22h do dia 30/08/2024.
      </div>
      <div class="item-instrucao-prova">
        <span>2.</span>
        Somente clique em <b>INICIAR PROVA</b> se tiver certeza de que terá
        tempo de terminá-la.
      </div>
      <div class="item-instrucao-prova">
        <span>3.</span>
        Ao clicar em <b>INICIAR PROVA</b>, você terá 120 minutos (2 horas) para
        finalizar a prova.
      </div>
      <div class="item-instrucao-prova">
        <span>4.</span>
        Mantenha papel e lápis facilmente acessíveis para realizar cálculos e
        fazer rascunhos durante a prova!
      </div>
      <div class="item-instrucao-prova">
        <span>5.</span>
        O tempo continuará contando a partir de seu clique no botão
        <b>INICIAR PROVA</b>.
      </div>
      <div class="item-instrucao-prova">
        <span>6.</span>
        Caso seu sistema fique offline, você poderá entrar novamente no sistema
        de qualquer dispositivo. A prova continuará de onde você parou,
        respeitando o limite de 120 minutos contados a partir do início da
        prova.
      </div>
      <div class="item-instrucao-prova">
        <span>7.</span>
        A prova é composta de <b>18 questões</b> de múltipla escolha, com cinco
        alternativas em cada e <b>APENAS UMA CORRETA</b>.
      </div>
      <div class="item-instrucao-prova">
        <span>8.</span>
        Não é possível revisar ou voltar a questões anteriores.
      </div>
      <div class="item-instrucao-prova">
        <span>9.</span>
        Não é possível avançar para a próxima questão sem resolver a questão
        aberta.
      </div>
      <div class="item-instrucao-prova">
        <span>10.</span>
        Após responder a última questão,
        <b class="atencao-txt">clique em FINALIZAR</b> para concluir a prova.
      </div>
      <div class="item-instrucao-prova">
        <span>11.</span>
        Confirme o texto abaixo para liberar o botão <b>INICIAR PROVA</b>!
      </div>
    </div>
    <div class="box-start-prova" :class="{ pulse: !check }">
      <div class="confirmacao-check" @click="confirmacaoProva()">
        <div class="check-icon-confirmacao">
          <v-icon v-if="!check" icon="mdi-checkbox-blank-outline"></v-icon>
          <v-icon v-if="check" icon="mdi-checkbox-marked"></v-icon>
        </div>
        <div class="text-confirmacao">
          Li as instruções da prova acima, estou ciente e pronto para iniciá-la!
          Compreendi que ao clicar no botão INICIAR PROVA, o tempo será iniciado
          e não será possível interromper o cronômetro. Para concluir a prova,
          devo clicar em FINALIZAR na ultima questão.
        </div>
      </div>
    </div>
    <div class="box-start-prova" elevation="8" :class="{ pulse: check }">
      <v-btn
        :disabled="!check"
        color="green"
        block
        prepend-icon="mdi-check"
        size="x-large"
        :class="{ 'desabilitado-inicio-prova': !check }"
        @click="startProva()"
        >INICIAR PROVA</v-btn
      >
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { getUserInfo } from "@/util/auth";

import ProvaService from "@/services/prova.service";

function isUndefined(variable) {
  return typeof variable === "undefined";
}

export default defineComponent({
  name: "InstrucaoView",
  props: {
    cuco: {
      type: String,
    },
  },
  setup(props) {
    const router = useRouter();
    const user = ref("");

    const alertError = ref({
      status: false,
      message: "",
      type: 1,
    });

    const loadingPage = ref(true);

    const check = ref(false);
    const idCuco = ref(props.cuco);

    const confirmacaoProva = () => {
      check.value = !check.value;
    };
    const startProva = async () => {
      if (check.value) {
        loadingPage.value = true;
        try {
          await ProvaService.start(idCuco.value);
          router.push(`/edicao/${idCuco.value}`);
        } catch (erro) {
          loadingPage.value = false;
          if (
            !isUndefined(erro.response?.data.message) &&
            erro.response?.data.message !== null &&
            erro.response?.data.message !== ""
          ) {
            alertError.value.message = erro?.response?.data?.message;
            alertError.value.type = 2;
            alertError.value.status = true;
          } else {
            alertError.value.message = "Erro interno! Tente novamente.";
            alertError.value.type = 2;
            alertError.value.status = true;
          }
        }
      }
    };
    return {
      check,
      confirmacaoProva,
      startProva,
      loadingPage,
      alertError,
      idCuco,
      user,
    };
  },

  async created() {
    const data = getUserInfo();
    this.user = data.name.split(" ")[0];

    try {
      const router = useRouter();
      const response = await ProvaService.status(this.cuco);
      if (response.data) {
        if (response.data.statusInit === 1) {
          //Finalizou o teste
          router.push(`/edicao/${this.cuco}/finalizado`);
        } else if (response.data.statusInit === 2) {
          //Não tem prova atribuida - Instrucoes
          this.loadingPage = false;
        } else if (response.data.statusInit === 3) {
          //Não iniciou a prova - Instrucoes
          this.loadingPage = false;
        } else if (response.data.statusInit === 4) {
          //Prova iniciada
          router.push(`/edicao/${this.cuco}`);
        } else if (response.data.statusInit === 5) {
          //Tempo finalizado
          router.push(`/edicao/${this.cuco}/tempo-esgotado`);
        } else {
          this.alertError.message =
            "Erro interno! Recarregue a página e tente novamente.";
          this.alertError.type = 1;
          this.alertError.status = true;
        }
      }
    } catch (erro) {
      this.loadingPage = false;
      if (
        !isUndefined(erro.response?.data.message) &&
        erro.response?.data.message !== null &&
        erro.response?.data.message !== ""
      ) {
        this.alertError.message = erro?.response?.data?.message;
        this.alertError.type = 1;
        this.alertError.status = true;
      } else {
        this.alertError.message =
          "Erro interno! Recarregue a página e tente novamente.";
        this.alertError.type = 1;
        this.alertError.status = true;
      }
    }
  },
});
</script>
<style scoped>
.start-prova {
  width: 100%;
  height: 100%;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 3% 60px;
}
.box-start-prova {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  width: 100%;
  margin: 20px 0;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0;
  border-radius: 4px;
  /* box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px 1px 1px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px 1px 3px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12)); */
}
.item-instrucao-prova {
  font-size: 1rem;
  margin: 16px 0;
}
.item-instrucao-prova span {
  font-weight: 900;
  color: #c95d21;
  border-radius: 50%;
  margin-right: 1px;
  min-width: 30px;
  padding: 1px;
}
.title-instrucao {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.sub-title-instrucao {
  font-size: 1rem;
  margin-bottom: 10px;
}
.confirmacao-check {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  user-select: none;
}
.check-icon-confirmacao {
  font-size: 1.3rem;
  margin-right: 16px;
  color: #4caf50;
}
.text-confirmacao {
  font-size: 1.1rem;
}
.pulse {
  box-shadow: 0 0 0 rgba(255 106 0 / 0.6);
  animation: pulse 2s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255 106 0 / 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255 106 0 / 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255 106 0 / 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255 106 0 / 0.6);
    box-shadow: 0 0 0 0 rgba(255 106 0 / 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255 106 0 / 0);
    box-shadow: 0 0 0 10px rgba(255 106 0 / 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255 106 0 / 0);
    box-shadow: 0 0 0 0 rgba(255 106 0 / 0);
  }
}
.title-sistema {
  font-size: 2rem;
}
.header-instrucoes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text-edicao {
  margin: 5px 0 0;
  font-size: 1.2rem;
}
.logo-prova {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 140px;
  flex-direction: row;
}
.atencao-txt {
  color: #b71c1c;
  font-weight: 700;
  text-decoration: underline;
}
.desabilitado-inicio-prova {
  cursor: no-drop;
  pointer-events: auto;
  background-color: #777 !important;
}
.loading-full {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #f47935;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 50px;
  height: 50px;
  border: 6px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>