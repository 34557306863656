<template>
  <div class="frame" v-if="alertError.status">
    <div class="modal-error">
      <v-icon class="icon-modal-error" icon="mdi-alert"></v-icon>
      <span class="title-erro">ERRO</span>
      <p>{{ alertError.message }}</p>

      <a v-if="alertError.type === 1" :href="`/edicao/${idCuco}`">
        <div class="button">OK</div>
      </a>

      <div
        v-if="alertError.type === 2"
        @click="alertError.status = false"
        class="button"
      >
        OK
      </div>
    </div>
  </div>

  <div class="loading" v-if="!questionsLoad">
    <span class="loader"></span>
    <div class="text-loading-q" v-if="numberQuestion !== 18">
      CARREGANDO<br />QUESTÃO...
    </div>
  </div>
  <div class="loading-full" v-if="loadingPage">
    <span class="loader"></span>
  </div>
  <v-app id="inspire" @copy="preventCopy" @contextmenu="preventRightClick">
    <v-app-bar class="header-top" absolute>
      <v-app-bar-nav-icon
        color="white"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-app-bar-title>
        <v-img
          :src="require('@/assets/img/logo-w.png')"
          max-height="28"
          width="80"
        ></v-img>
      </v-app-bar-title>
      <template v-slot:append>
        <div class="user-header">
          <v-menu>
            <template v-slot:activator="{ props }">
              <!-- <v-btn icon="mdi-dots-vertical" ></v-btn> -->
              <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>

            <v-list>
              <v-list-item min-width="150" @click="perfil()">
                <v-list-item-title> Perfil </v-list-item-title>
              </v-list-item>
              <v-list-item min-width="150" @click="logout()">
                <v-list-item-title> Sair </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-app-bar>
    <MenuQuiz
      :controle="drawer"
      @toggleMenu="drawer = $event"
      :num_question="numberQuestion"
      :info_quiz="quiz"
    ></MenuQuiz>

    <v-main>
      <v-container>
        <v-breadcrumbs
          v-if="!loadingPage"
          class="breadcrumbs-quiz"
          :items="items"
        ></v-breadcrumbs>
        <div
          class="timer-box"
          :class="{ 'alerta-tempo': alertaTempo, pulse: pulseTempo }"
          v-if="timer"
        >
          <v-icon class="" icon="mdi-alarm"></v-icon>
          <p>
            {{ setZero(timer?.hours) }}:{{ setZero(timer?.minutes) }}:{{
              setZero(timer?.seconds)
            }}
          </p>
        </div>
        <div class="seletor-do-elemento" v-if="questionsLoad && !loadingPage">
          <v-card class="card-question">
            <v-card-text>
              <div class="num-label-quest-quiz">
                Questão {{ numberQuestion }}
              </div>
              <!-- <div class="code-question">{{ question.titleQuestion }}</div> -->
              <div class="text-pergunta" v-html="question.textQuestion"></div>
              <v-radio-group v-model="respostaSelecionada">
                <v-radio
                  v-for="(alternativa, key) in alternativas"
                  :key="alternativa.idAlternative"
                  :value="alternativa.idAlternative"
                  color="blue"
                  class="alternativa-questao"
                  :class="{
                    'active-alternativa':
                      respostaSelecionada === alternativa.idAlternative,
                  }"
                >
                  <template #label>
                    <div class="cont-alternativa">
                      <span>{{ letrasAlternativas[key] }}) </span>
                      <div v-html="alternativa.textAlternative"></div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
          <div class="btns-nav-quiz">
            <button
              class="pushable"
              :disabled="!respostaSelecionada"
              @click="proximaQuestao()"
              v-if="numberQuestion !== 18"
            >
              <span class="front">
                CONTINUAR
                <v-icon dark large> mdi-chevron-right </v-icon>
              </span>
            </button>

            <button
              class="pushable"
              :disabled="!respostaSelecionada"
              @click="finalizarProva()"
              v-if="numberQuestion === 18"
            >
              <span class="front">
                <v-icon dark large> mdi-check </v-icon>
                FINALIZAR
              </span>
            </button>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, watchEffect, ref } from "vue";
//import moment from "moment-timezone";
import { useRouter } from "vue-router";

import { useTimer } from "vue-timer-hook";
import MenuQuiz from "@/components/MenuQuiz.vue";

import ProvaService from "@/services/prova.service";
import { removerCookie } from "@/util/cookie";

// Função para randomizar array
function seedShuffle(seed, array) {
  // Função para embaralhar o array usando a semente
  function shuffleArray(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
  }

  // Função para gerar um número pseudo-aleatório usando a semente
  function random() {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  // Copia o array original para não modificar o original
  const shuffledArray = [...array];

  // Embaralha o array usando a semente definida
  shuffleArray(shuffledArray);

  return shuffledArray;
}

function isUndefined(variable) {
  return typeof variable === "undefined";
}

export default defineComponent({
  name: "QuizView",
  components: {
    MenuQuiz,
  },
  props: {
    cuco: {
      type: String,
    },
  },
  data: () => ({
    quiz: null,
    drawer: null,
    items: null,
    questionid: 0,
    isFirst: true,
  }),
  methods: {
    preventCopy(event) {
      event.preventDefault();
    },
    preventRightClick(event) {
      event.preventDefault();
    },
  },
  setup(props) {
    const router = useRouter();

    const idCuco = ref(props.cuco);

    const loadingPage = ref(true);
    const questionsLoad = ref(true);
    const timer = ref(null);
    const numberQuestion = ref(0);
    const question = ref(null);
    const alternativas = ref(null);

    const alertError = ref({
      status: false,
      message: "",
      type: 1,
    });

    const alertaTempo = ref(false);
    const pulseTempo = ref(false);

    const letrasAlternativas = ref(["A", "B", "C", "D", "E"]);

    const respostaSelecionada = ref(null);

    const setZero = (data) => {
      if (data === 0) {
        return "00";
      } else if (data < 10) {
        return `0${data}`;
      }
      return data;
    };

    const proximaQuestao = async () => {
      try {
        questionsLoad.value = false;
        const response = await ProvaService.save(props.cuco, {
          idQuestion: question.value.idQuestion,
          idAlternative: respostaSelecionada.value,
        });

        numberQuestion.value = response.data.numberQuestion;
        question.value = response.data.question;
        alternativas.value = seedShuffle(
          response.data.seedTest,
          response.data.question.alternative
        );
        respostaSelecionada.value = null;

        questionsLoad.value = true;
      } catch (erro) {
        questionsLoad.value = true;
        if (
          !isUndefined(erro.response?.data.message) &&
          erro.response?.data.message !== null &&
          erro.response?.data.message !== ""
        ) {
          alertError.value.message = erro?.response?.data?.message;
          alertError.value.type = 1;
          alertError.value.status = true;
        } else {
          alertError.value.message =
            "Erro interno! Recarregue a página e tente novamente.";
          alertError.value.type = 1;
          alertError.value.status = true;
        }
      }
    };

    const finalizarProva = async () => {
      try {
        questionsLoad.value = false;
        const response = await ProvaService.save(props.cuco, {
          idQuestion: question.value.idQuestion,
          idAlternative: respostaSelecionada.value,
        });

        if (response) {
          router.push(`/edicao/${props.cuco}/finalizado`);
        }
      } catch (erro) {
        questionsLoad.value = true;
        if (
          !isUndefined(erro.response?.data.message) &&
          erro.response?.data.message !== null &&
          erro.response?.data.message !== ""
        ) {
          alertError.value.message = erro?.response?.data?.message;
          alertError.value.type = 1;
          alertError.value.status = true;
        } else {
          alertError.value.message =
            "Erro interno! Recarregue a página e tente novamente.";
          alertError.value.type = 1;
          alertError.value.status = true;
        }
      }
    };

    const logout = () => {
      removerCookie("aluno-cuco");
      window.location.href = `${process.env.VUE_APP_URL_DASH}login`;
    };

    const perfil = () => {
      window.location.href = `${process.env.VUE_APP_URL_DASH}perfil`;
    };

    watchEffect(async () => {
      if (timer.value) {
        if (timer.value.hours === 0 && timer.value.minutes < 30) {
          alertaTempo.value = true;
        }

        if (timer.value.hours === 0 && timer.value.minutes < 5) {
          pulseTempo.value = true;
        }
        if (timer.value.isExpired) {
          if (respostaSelecionada.value !== null) {
            await ProvaService.saveEnd(props.cuco, {
              idQuestion: question.value.idQuestion,
              idAlternative: respostaSelecionada.value,
            });
          }
          router.push(`/edicao/${props.cuco}/tempo-esgotado`);
        }
      }
    });

    return {
      timer,
      setZero,
      loadingPage,
      numberQuestion,
      question,
      respostaSelecionada,
      alternativas,
      letrasAlternativas,
      proximaQuestao,
      questionsLoad,
      alertaTempo,
      pulseTempo,
      alertError,
      finalizarProva,
      idCuco,
      logout,
      perfil,
    };
  },
  async created() {
    this.items = [
      {
        text: "Dashboard",
        disabled: false,
        href: `${process.env.VUE_APP_URL_DASH}edicoes/${this.cuco}`,
      },
      {
        text: "CUCO 2024",
        disabled: true,
        href: `#`,
      },
    ];
    try {
      const router = useRouter();
      const response = await ProvaService.init(this.cuco);
      if (response.data) {
        if (response.data.statusInit === 1) {
          //Finalizou o teste
          router.push(`/edicao/${this.cuco}/finalizado`);
        } else if (response.data.statusInit === 2) {
          //Não tem prova atribuida - Instrucoes
          router.push(`/edicao/${this.cuco}/instrucoes`);
        } else if (response.data.statusInit === 3) {
          //Não iniciou a prova - Instrucoes
          router.push(`/edicao/${this.cuco}/instrucoes`);
        } else if (response.data.statusInit === 4) {
          //Prova iniciada
          this.numberQuestion = response.data.numberQuestion;
          this.question = response.data.question;
          this.alternativas = seedShuffle(
            response.data.seedTest,
            response.data.question.alternative
          );
          const time = new Date(response.data.clock);
          this.timer = useTimer(time, false);
          this.loadingPage = false;
          this.timer.start();
        } else if (response.data.statusInit === 5) {
          //Tempo finalizado
          router.push(`/edicao/${this.cuco}/tempo-esgotado`);
        } else {
          this.alertError.message =
            "Erro interno! Recarregue a página e tente novamente.";
          this.alertError.type = 1;
          this.alertError.status = true;
        }
      }
    } catch (erro) {
      this.loadingPage = false;
      if (
        !isUndefined(erro.response?.data.message) &&
        erro.response?.data.message !== null &&
        erro.response?.data.message !== ""
      ) {
        this.alertError.message = erro?.response?.data?.message;
        this.alertError.type = 1;
        this.alertError.status = true;
      } else {
        this.alertError.message =
          "Erro interno! Recarregue a página e tente novamente.";
        this.alertError.type = 1;
        this.alertError.status = true;
      }
    }
  },
});
</script>
<style scoped>
.header-top {
  background-color: #f47935;
}
.timer-box {
  margin: 5px 12px 25px;
  background-color: #4698e9;
  color: #fff;
  font-weight: 700;
  padding: 12px 20px 10px;
  line-height: normal;
  border-radius: 5px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.timer-box p {
  margin: 0 10px;
  font-size: 1.2rem;
}
.load-page-quiz {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background-color: rgb(255 255 255 / 80%);
}
.load-page-quiz > div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}
header {
  top: 0 !important;
  left: 0 !important;
}
/* nav,
main {
  margin-top: 4rem !important;
} */
/* nav {
  max-height: calc(100% - 7rem) !important;
  border-color: transparent !important;
  border: 0 !important;
  position: absolute !important;
  padding-top: 64px !important;
} */
main {
  background-color: #eee;
}
.titulo-site {
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
  color: #fff;
}
footer {
  background-color: #222222 !important;
  color: #fff !important;
}
.user-header {
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
.icon-user-header {
  margin-right: 10px;
}
.text-pergunta {
  margin: 25px 0;
  font-size: 1rem;
  line-height: 1.5rem;
}
.num-label-quest-quiz {
  color: #222;
  font-size: 20px;
  font-weight: bold;
  /* padding: 10px; */
  width: auto;
  display: table;
  border-radius: 2px;
  margin-bottom: 16px;
  margin-top: 10px;
}
.blue--text {
  color: #1976d2;
}
.alternativa-questao {
  margin: 20px 0 20px -10px;
}
.btns-nav-quiz {
  margin: 30px 12px;
}
.btns-nav-quiz button {
  width: 100%;
}

.card-question {
  margin: 10px 12px;
}
.active-alternativa {
  color: #1976d2;
}
.pushable {
  background: #238d38;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.pushable:focus:not(:focus-visible) {
  outline: none;
}
.pushable:disabled,
.pushable[disabled] {
  background-color: #57c56c;
}

.pushable:disabled .front,
.pushable[disabled] .front {
  background-color: #67d17b;
  cursor: no-drop;
}

.pushable:disabled:active .front {
  transform: translateY(-6px);
}

.front {
  display: flex;
  justify-content: center;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #36a74c;
  color: #fff;
  transform: translateY(-6px);
  font-weight: bold;
}

.pushable:active .front {
  transform: translateY(-2px);
}
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgb(0 0 0 / 80%);
  z-index: 99999;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text-loading-q {
  font-weight: 500;
  letter-spacing: 3px;
  color: #fff;
  margin-top: 20px;
  text-align: center;
}
.loading-full {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #f47935;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 50px;
  height: 50px;
  border: 6px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cont-alternativa {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
}
.cont-alternativa span {
  font-weight: 900;
  margin-right: 4px;
  min-width: 22px;
}
.seletor-do-elemento {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.alerta-tempo {
  background-color: #e53935;
}
.pulse {
  box-shadow: 0 0 0 rgba(229 57 53 / 0.6);
  animation: pulse 2s infinite;
}
.code-question {
  font-size: 0.7rem;
  margin-bottom: 16px;
  color: #555;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(229 57 53 / 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(229 57 53 / 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(229 57 53 / 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(229 57 53 / 0.6);
    box-shadow: 0 0 0 0 rgba(229 57 53 / 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(229 57 53 / 0);
    box-shadow: 0 0 0 10px rgba(229 57 53 / 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(229 57 53 / 0);
    box-shadow: 0 0 0 0 rgba(229 57 53 / 0);
  }
}
</style>
<style>
.v-selection-control {
  align-items: flex-start;
}
.v-selection-control__wrapper {
  height: 24px !important;
}
.v-selection-control .v-label {
  opacity: 1 !important;
}
@media (max-width: 500px) {
  .img-resp-questao {
    width: 100% !important;
    height: auto !important;
  }
}
</style>