<template>
  <div>
    <div v-if="questions_load">
      <v-progress-circular
        :size="50"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-navigation-drawer
      v-model="controle_aux"
      class="bg-menu-quiz"
      width="300"
      app
    >
      <div class="title-number-content">
        <p class="text-h6">CUCO 2024</p>
        <p>Questões</p>
        <div class="box-question-quiz">
          <div
            class="num-question"
            :class="{
              'questao-respondida': n < num_question,
              'questao-atual': n === num_question,
            }"
            v-for="n in 18"
            :key="n"
          >
            {{ n }}
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuQuiz",
  data: () => ({
    drawer: null,
    dialog: false,
    erro: false,
    success: false,
    text_erro: "",
    questions_load: false,
    controle_aux: false,
  }),
  props: {
    controle: {
      required: true,
      default: false,
    },
    num_question: {
      required: true,
      default: 0,
    },
    info_quiz: {
      require: true,
      default: null,
    },
  },
  created() {
    this.controle_aux = this.controle;
  },
  watch: {
    controle_aux(newValue) {
      this.$emit("toggleMenu", newValue);
    },
    controle() {
      this.controle_aux = this.controle;
    },
  },
});
</script>
<style scoped>
nav.m {
  background-color: #e0e0e0 !important;
}
.box-question-quiz {
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
}
.num-question {
  text-align: center;
  background-color: #bbbbbb;
  border-radius: 4px;
  line-height: 1;
  color: #656464;
  padding: 10px 0 8px;
}
.box-btn-question button {
  margin: 0 3% 10px 0;
  width: 22%;
}
.btn-finalizar {
  color: #fff;
  font-size: 0.9rem;
}
.box-question-quiz {
  margin: 10px 0;
  width: 100%;
}
.box-question-quiz div {
  width: 22%;
  margin: 0 3% 10px 0;
}
.box-question-quiz div:nth-child(4n + 0) {
  margin-right: 0;
}
.box-question-quiz div:nth-child(4n + 1) {
  margin-left: 0;
}
.title-number-content {
  padding: 32px 20px 0;
}
.btn-ok {
  color: #fff;
}
.btns-action-alert {
  padding-bottom: 20px !important;
}
.btn-num-active {
  color: #fff;
  background-color: #1976d2 !important;
}
nav {
  padding-top: 64px !important;
}
.load-page-quiz {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background-color: rgb(255 255 255 / 80%);
}
.load-page-quiz > div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}
.questao-respondida {
  background-color: #4698e9;
  color: #fff;
}
.questao-atual {
  background-color: #1976d2;
  color: #fff;
  font-weight: 700;
}
</style>