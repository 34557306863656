import { createRouter, createWebHistory } from 'vue-router'

import QuizView from '@/views/QuizView.vue'
import InstrucoesView from '@/views/StartView.vue'
import EndTimeView from '@/views/EndTimeView.vue'
import EndTestView from '@/views/EndTestView.vue'

import { isLoggedIn } from '@/util/auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_URL_DASH}`;
    },
  },
  {
    path: '/edicao/:cuco',
    name: 'Quiz',
    props: true,
    component: QuizView,
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_URL_DASH}`;
    },
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/edicao/:cuco/instrucoes',
    name: 'Instruções',
    props: true,
    component: InstrucoesView,
  },
  {
    path: '/edicao/:cuco/tempo-esgotado',
    name: 'Tempo esgotado',
    props: true,
    component: EndTimeView,
  },
  {
    path: '/edicao/:cuco/finalizado',
    name: 'Finalizado',
    props: true,
    component: EndTestView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/Page404View.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.allowAnonymous && isLoggedIn()) {
    next({ path: '/' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/login',
    })
  } else {
    next()
  }
})

export default router
